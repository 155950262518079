import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "new-customer-";
export default {
  path: "/new-customer",
  name: "new-customer",
  redirect: {
    name: `${pre}channel-qr`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "channel-qr",
    name: `${pre}channel-qr`,
    meta: { ...meta,
      title: "渠道活码"
    },
    component: () => import("@/pages/new-customer/channel-qr")
  }, {
    path: "store-qr",
    name: `${pre}store-qr`,
    meta: { ...meta,
      title: "地区活码"
    },
    component: () => import("@/pages/new-customer/store-qr")
  }, {
    path: "channel-qr/create",
    name: `${pre}channel-qr-create`,
    meta: { ...meta,
      title: "渠道活码-创建"
    },
    component: () => import("@/pages/new-customer/channel-qr/createOrUpdate.vue")
  }, {
    path: "channel-qr/update",
    name: `${pre}channel-qr-update`,
    meta: { ...meta,
      title: "渠道活码-修改"
    },
    component: () => import("@/pages/new-customer/channel-qr/createOrUpdate.vue")
  }, {
    path: "channel-qr/copy",
    name: `${pre}channel-qr-copy`,
    meta: { ...meta,
      title: "渠道活码-复制"
    },
    component: () => import("@/pages/new-customer/channel-qr/createOrUpdate.vue")
  }, {
    path: "channel-qr/count",
    name: `${pre}channel-qr-count`,
    meta: { ...meta,
      title: "渠道活码-数据统计"
    },
    component: () => import("@/pages/new-customer/channel-qr/count.vue")
  }, {
    path: "add-friends",
    name: `${pre}add-friends`,
    meta: { ...meta,
      title: "批量加好友"
    },
    component: () => import("@/pages/new-customer/add-friends/index.vue")
  }, {
    path: "add-friends/info",
    name: `${pre}add-friends/info`,
    meta: { ...meta,
      title: "导入记录"
    },
    component: () => import("@/pages/new-customer/add-friends/components/info.vue")
  }]
};