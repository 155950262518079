/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "i-layout-header-trigger i-layout-header-trigger-min"
};
const _hoisted_2 = {
  class: "i-layout-header-user-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Avatar = _resolveComponent("Avatar");

  const _component_Icon = _resolveComponent("Icon");

  const _component_DropdownItem = _resolveComponent("DropdownItem");

  const _component_DropdownMenu = _resolveComponent("DropdownMenu");

  const _component_Dropdown = _resolveComponent("Dropdown");

  return _openBlock(), _createBlock(_component_Dropdown, {
    trigger: _ctx.isMobile ? 'click' : 'hover',
    class: _normalizeClass(["i-layout-header-user", {
      'i-layout-header-user-mobile': _ctx.isMobile
    }]),
    onOnClick: $options.handleClick
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [_createCommentVNode(" <i-link to=\"/setting/user\">\n                    <DropdownItem>\n                        <Icon type=\"ios-contact-outline\" />\n                        <span>{{ $t('basicLayout.user.center') }}</span>\n                    </DropdownItem>\n                </i-link>\n                <i-link to=\"/setting/account\">\n                    <DropdownItem>\n                        <Icon type=\"ios-settings-outline\" />\n                        <span>{{ $t('basicLayout.user.setting') }}</span>\n                    </DropdownItem>\n                </i-link> "), _createVNode(_component_DropdownItem, {
        divided: "",
        name: "logout"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "ios-log-out"
        }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('basicLayout.user.logOut')), 1
        /* TEXT */
        )]),
        _: 1
        /* STABLE */

      })]),
      _: 1
      /* STABLE */

    })]),
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_Avatar, {
      src: _ctx.info.avatar ? _ctx.info.avatar : _ctx.defaultAvatar
    }, null, 8
    /* PROPS */
    , ["src"]), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.info.name), 1
    /* TEXT */
    )])]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["trigger", "class", "onOnClick"]);
}