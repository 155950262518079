import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'wechat-service-';
export default {
  path: '/wechat-service',
  name: 'wechatService',
  redirect: {
    name: `${pre}service-reception`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: 'service-reception',
    name: `${pre}service-reception`,
    meta: { ...meta,
      title: '客服接待'
    },
    component: () => import('@/pages/wechat-service/service-reception')
  }]
};