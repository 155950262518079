import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'sys-config-';
export default {
  path: '/sys-config',
  name: 'SysConfig',
  redirect: {
    name: `${pre}member-list`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: 'member-list',
    name: `${pre}member-list`,
    meta: { ...meta,
      title: '成员管理'
    },
    component: () => import('@/pages/sys-config/member-list')
  }, {
    path: 'permission-manage',
    name: `${pre}permission-manage`,
    meta: { ...meta,
      title: '权限管理'
    },
    component: () => import('@/pages/sys-config/permission-manage')
  }, {
    path: 'sensitive',
    name: `${pre}sensitive`,
    meta: { ...meta,
      title: '敏感消息管理'
    },
    component: () => import('@/pages/sys-config/sensitive')
  }]
};