import cookies from './util.cookies';
import log from './util.log';
import db from './util.db';
import Setting from '@/setting';
const util = {
  cookies,
  log,
  db
};

function tTitle(title = '') {
  const $t = window ? window.$app.$t : null;

  if ($t) {
    if (title.indexOf('$t:') === 0) {
      return $t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}

util.sizeTostr = function (size) {
  var data = "";

  if (size < 0.1 * 1024) {
    //如果小于0.1KB转化成B  
    data = size.toFixed(2) + "B";
  } else if (size < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB  
    data = (size / 1024).toFixed(2) + "KB";
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    //如果小于0.1GB转化成MB  
    data = (size / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB  
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizestr = data + "";
  var len = sizestr.indexOf("\.");
  var dec = sizestr.substr(len + 1, 2);

  if (dec == "00") {
    //当小数点后为00时 去掉小数部分  
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }

  return sizestr;
};
/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */


util.title = function ({
  title,
  count
}) {
  title = tTitle(title);
  let fullTitle;

  if (Setting.titleSuffix === false || Setting.titleSuffix === '') {
    fullTitle = title ? `${title}` : '';
  } else {
    fullTitle = title ? `${title} - ${Setting.titleSuffix}` : Setting.titleSuffix;
  }

  if (count) fullTitle = `(${count}条消息)${fullTitle}`;
  window.document.title = fullTitle;
};

util.Mul = function (arg1, arg2) {
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();

  try {
    m += s1.split(".")[1].length;
  } catch (e) {}

  try {
    m += s2.split(".")[1].length;
  } catch (e) {}

  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
};

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}

export { requestAnimation };
export default util;