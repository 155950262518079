/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = _resolveComponent("i-menu-side-title");

  const _component_Badge = _resolveComponent("Badge");

  const _component_i_menu_side_item = _resolveComponent("i-menu-side-item");

  const _component_i_menu_side_submenu = _resolveComponent("i-menu-side-submenu");

  const _component_Submenu = _resolveComponent("Submenu", true);

  return _openBlock(), _createBlock(_component_Submenu, {
    name: $props.menu.path
  }, {
    title: _withCtx(() => [_createElementVNode("div", {
      onMouseover: _cache[0] || (_cache[0] = $event => $options.menuM($props.menu, 1)),
      onMouseleave: _cache[1] || (_cache[1] = $event => $options.menuM($props.menu, 2))
    }, [_createVNode(_component_i_menu_side_title, {
      menu: $props.menu,
      tit: $data.tit
    }, null, 8
    /* PROPS */
    , ["menu", "tit"]), _ctx.badgeData ? (_openBlock(), _createBlock(_component_Badge, _mergeProps({
      key: 0,
      class: "i-layout-menu-side-badge"
    }, _ctx.badgeData), null, 16
    /* FULL_PROPS */
    )) : _createCommentVNode("v-if", true)], 32
    /* NEED_HYDRATION */
    )]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu.children, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [item.children === undefined || !item.children.length ? (_openBlock(), _createBlock(_component_i_menu_side_item, {
        menu: item,
        key: index
      }, null, 8
      /* PROPS */
      , ["menu"])) : (_openBlock(), _createBlock(_component_i_menu_side_submenu, {
        key: 1,
        menu: item
      }, null, 8
      /* PROPS */
      , ["menu"]))], 64
      /* STABLE_FRAGMENT */
      );
    }), 128
    /* KEYED_FRAGMENT */
    ))]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["name"]);
}