/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");

  return _openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["i-layout-header-trigger", {
      'i-layout-header-trigger-min': _ctx.showSiderCollapse
    }]),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleReload && $options.handleReload(...args))
  }, [_createVNode(_component_Icon, {
    custom: "i-icon i-icon-refresh"
  })], 2
  /* CLASS */
  );
}