/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");

  const _component_DropdownItem = _resolveComponent("DropdownItem");

  const _component_DropdownMenu = _resolveComponent("DropdownMenu");

  const _component_Dropdown = _resolveComponent("Dropdown");

  return _openBlock(), _createBlock(_component_Dropdown, {
    trigger: _ctx.isMobile ? 'click' : 'hover',
    class: _normalizeClass(["i-layout-header-i18n", {
      'i-layout-header-user-mobile': _ctx.isMobile
    }]),
    onOnClick: $options.handleClick
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.languages, item => {
        return _openBlock(), _createBlock(_component_DropdownItem, {
          key: item.locale,
          name: item.locale,
          selected: _ctx.locale === item.locale
        }, {
          default: _withCtx(() => [item.icon ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.icon
          }, null, 8
          /* PROPS */
          , _hoisted_1)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(item.language), 1
          /* TEXT */
          )]),
          _: 2
          /* DYNAMIC */

        }, 1032
        /* PROPS, DYNAMIC_SLOTS */
        , ["name", "selected"]);
      }), 128
      /* KEYED_FRAGMENT */
      ))]),
      _: 1
      /* STABLE */

    })]),
    default: _withCtx(() => [_createElementVNode("span", {
      class: _normalizeClass(["i-layout-header-trigger i-layout-header-trigger-min", {
        'i-layout-header-trigger-no-height': $props.outside
      }])
    }, [_createVNode(_component_Icon, {
      custom: "i-icon i-icon-i18n"
    })], 2
    /* CLASS */
    )]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["trigger", "class", "onOnClick"]);
}