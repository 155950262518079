/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");

  return _openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["i-layout-header-trigger", {
      'i-layout-header-trigger-min': _ctx.showReload
    }]),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleToggleMenuSide && $options.handleToggleMenuSide(...args))
  }, [_withDirectives(_createVNode(_component_Icon, {
    custom: "i-icon i-icon-menu-unfold"
  }, null, 512
  /* NEED_PATCH */
  ), [[_vShow, _ctx.menuCollapse || _ctx.isMobile]]), _withDirectives(_createVNode(_component_Icon, {
    custom: "i-icon i-icon-menu-fold"
  }, null, 512
  /* NEED_PATCH */
  ), [[_vShow, !_ctx.menuCollapse && !_ctx.isMobile]])], 2
  /* CLASS */
  );
}