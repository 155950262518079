/* unplugin-vue-components disabled */import { mapState } from 'vuex';
export default {
  name: 'iHeaderReload',
  emits: ['on-reload'],
  computed: { ...mapState('admin/layout', ['isMobile', 'showSiderCollapse'])
  },
  methods: {
    handleReload() {
      this.$emit('on-reload');
    }

  }
};