/* unplugin-vue-components disabled */import iMenuHeadTitle from './title';
import { mapState, mapGetters } from 'vuex';
import { getStyle } from 'view-ui-plus/src/utils/assist';
import clickItem from '../mixins/click-item';
import Setting from '@/setting'; // import { on, off } from 'view-ui-plus/src/utils/dom';
// import { throttle } from 'lodash';

export default {
  name: 'iMenuHead',
  components: {
    iMenuHeadTitle
  },
  mixins: [clickItem],
  computed: { ...mapState('admin/layout', ['isMobile']),
    ...mapState('admin/menu', ['headerName']),
    ...mapGetters('admin/menu', ['filterHeader'])
  },

  data() {
    return {
      handleResize: () => {},
      isMenuLimit: false,
      menuMaxWidth: 0 // 达到这个值后，menu 就显示不下了

    };
  },

  methods: {
    handleGetMenuHeight() {
      const menuWidth = parseInt(getStyle(this.$el, 'width'));
      const $menu = this.$refs.menu;

      if ($menu) {
        const menuHeight = parseInt(getStyle(this.$refs.menu.$el, 'height'));

        if (menuHeight > Setting.headerHeight) {
          if (!this.isMenuLimit) {
            this.menuMaxWidth = menuWidth;
          }

          this.isMenuLimit = true;
        }
      } else if (menuWidth >= this.menuMaxWidth) {
        this.isMenuLimit = false;
      }
    }

  },
  watch: {
    filterHeader() {
      this.handleGetMenuHeight();
    },

    isMobile() {
      this.handleGetMenuHeight();
    }

  },

  mounted() {
    // this.handleResize = throttle(this.handleGetMenuHeight, 100, { leading: false });
    // on(window, 'resize', this.handleResize);
    this.handleGetMenuHeight();
  },

  beforeUnmount() {// off(window, 'resize', this.handleResize);
  }

};