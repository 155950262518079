import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "sales-management-";
export default {
  path: "/sales-management",
  name: "sales-management",
  redirect: {
    name: `${pre}clue-highSeas`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "clue-highSeas",
    name: `${pre}clue-highSeas`,
    meta: { ...meta,
      title: "线索公海"
    },
    component: () => import("@/pages/sales-management/clue-highSeas")
  }, {
    path: "clue-highSeas/create",
    name: `${pre}clue-highSeas-create`,
    meta: { ...meta,
      title: "公海-创建"
    },
    component: () => import("@/pages/sales-management/clue-highSeas/create")
  }, {
    path: "qualified-clues",
    name: `${pre}qualified-clues`,
    meta: { ...meta,
      title: "线索合格定义"
    },
    component: () => import("@/pages/sales-management/qualified-clues")
  }, {
    path: "custom-sop",
    name: `${pre}custom-sop`,
    meta: { ...meta,
      title: "客户SOP"
    },
    component: () => import("@/pages/sales-management/custom-sop")
  }, {
    path: 'custom-sop/create',
    name: `${pre}custom-sop-create`,
    meta: { ...meta,
      title: '创建客户SOP'
    },
    component: () => import('@/pages/sales-management/custom-sop/create.vue')
  }, {
    path: 'custom-sop/data',
    name: `${pre}custom-sop-data`,
    meta: { ...meta,
      title: '执行数据'
    },
    component: () => import('@/pages/sales-management/custom-sop/data.vue')
  }, {
    path: "business-management",
    name: `${pre}business-management`,
    meta: { ...meta,
      title: "商机管理"
    },
    component: () => import("@/pages/sales-management/business-management")
  }, {
    path: "business-management/create",
    name: `${pre}business-management-create`,
    meta: { ...meta,
      title: "商机-创建"
    },
    component: () => import("@/pages/sales-management/business-management/create.vue")
  }, {
    path: "order-management",
    name: `${pre}order-management`,
    meta: { ...meta,
      title: "订单管理"
    },
    component: () => import("@/pages/sales-management/order-management")
  }, {
    path: 'order-management/create',
    name: `${pre}order-management-create`,
    meta: { ...meta,
      title: '新建订单'
    },
    component: () => import('@/pages/sales-management/order-management/create.vue')
  }, {
    path: 'order-management/setting',
    name: `${pre}order-management-setting`,
    meta: { ...meta,
      title: '订单设置'
    },
    component: () => import('@/pages/sales-management/order-management/setting.vue')
  }]
};