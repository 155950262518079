/* unplugin-vue-components disabled */import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tooltip = _resolveComponent("Tooltip");

  return _openBlock(), _createBlock(_component_Tooltip, {
    content: $options.tooltipContent,
    transfer: ""
  }, {
    default: _withCtx(() => [_createCommentVNode(" <span class=\"i-layout-header-trigger i-layout-header-trigger-min\" @click=\"handleOpenLog\">\n            <Badge :count=\"lengthError === 0 ? null : lengthError\" :overflow-count=\"99\" :dot=\"showDot\" :offset=\"showDot ? [26, 2] : [20, 0]\">\n                <Icon custom=\"i-icon i-icon-record\" />\n            </Badge>\n        </span> ")]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["content"]);
}