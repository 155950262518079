/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "i-layout-menu-side-collapse-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = _resolveComponent("i-menu-side-title");

  const _component_Icon = _resolveComponent("Icon");

  const _component_DropdownItem = _resolveComponent("DropdownItem");

  const _component_i_link = _resolveComponent("i-link");

  const _component_i_menu_side_collapse = _resolveComponent("i-menu-side-collapse");

  const _component_DropdownMenu = _resolveComponent("DropdownMenu");

  const _component_Dropdown = _resolveComponent("Dropdown");

  return _openBlock(), _createBlock(_component_Dropdown, {
    transfer: false,
    "boundaries-element": "viewport",
    placement: "right-start",
    class: _normalizeClass($options.dropdownClasses)
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, {
      class: "i-layout-menu-side-collapse-dropdown-menu",
      style: _normalizeStyle($options.dropdownMenuMaxHeight)
    }, {
      default: _withCtx(() => [_ctx.showCollapseMenuTitle ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_i_menu_side_title, {
        menu: $props.menu,
        collapse: ""
      }, null, 8
      /* PROPS */
      , ["menu"])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu.children, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.children === undefined || !item.children.length ? (_openBlock(), _createBlock(_component_i_link, {
          to: item.path,
          target: item.target,
          key: index,
          onClick: $event => _ctx.handleClick(item.path)
        }, {
          default: _withCtx(() => [_createVNode(_component_DropdownItem, {
            divided: item.divided,
            class: _normalizeClass({
              'i-layout-menu-side-collapse-item-selected': item.path === _ctx.activePath
            })
          }, {
            default: _withCtx(() => [_createVNode(_component_i_menu_side_title, {
              menu: item,
              collapse: ""
            }, null, 8
            /* PROPS */
            , ["menu"])]),
            _: 2
            /* DYNAMIC */

          }, 1032
          /* PROPS, DYNAMIC_SLOTS */
          , ["divided", "class"])]),
          _: 2
          /* DYNAMIC */

        }, 1032
        /* PROPS, DYNAMIC_SLOTS */
        , ["to", "target", "onClick"])) : (_openBlock(), _createBlock(_component_i_menu_side_collapse, {
          key: 1,
          menu: item
        }, null, 8
        /* PROPS */
        , ["menu"]))], 64
        /* STABLE_FRAGMENT */
        );
      }), 128
      /* KEYED_FRAGMENT */
      ))]),
      _: 1
      /* STABLE */

    }, 8
    /* PROPS */
    , ["style"])]),
    default: _withCtx(() => [$props.topLevel ? (_openBlock(), _createElementBlock("li", {
      key: 0,
      class: _normalizeClass($options.menuItemClasses),
      onMouseover: _cache[0] || (_cache[0] = $event => $options.menuM($props.menu, 1)),
      onMouseleave: _cache[1] || (_cache[1] = $event => $options.menuM($props.menu, 2))
    }, [_createVNode(_component_i_menu_side_title, {
      menu: $props.menu,
      "hide-title": "",
      collapse: "",
      tit: $data.tit
    }, null, 8
    /* PROPS */
    , ["menu", "tit"])], 34
    /* CLASS, NEED_HYDRATION */
    )) : (_openBlock(), _createBlock(_component_DropdownItem, {
      key: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_i_menu_side_title, {
        menu: $props.menu,
        selected: _ctx.openNames.indexOf($props.menu.path) >= 0,
        collapse: ""
      }, null, 8
      /* PROPS */
      , ["menu", "selected"]), _createVNode(_component_Icon, {
        type: "ios-arrow-forward",
        class: "i-layout-menu-side-arrow"
      })]),
      _: 1
      /* STABLE */

    }))]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["class"]);
}