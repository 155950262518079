import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "custom-operate-";
export default {
  path: "/custom-operate",
  name: "custom-operate",
  redirect: {
    name: `${pre}mass-send`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "mass-send",
    name: `${pre}mass-send`,
    meta: { ...meta,
      title: "客户群发"
    },
    component: () => import("@/pages/custom-operate/mass-send")
  }, {
    path: "mass-send/info",
    name: `${pre}mass-send-info`,
    meta: { ...meta,
      title: "群发详情"
    },
    component: () => import("@/pages/custom-operate/mass-send/info.vue")
  }, {
    path: "mass-send/add",
    name: `${pre}mmass-send-add`,
    meta: { ...meta,
      title: "新建群发"
    },
    component: () => import("@/pages/custom-operate/mass-send/add.vue")
  }, {
    path: "interactive-radar",
    name: `${pre}interactive-radar`,
    meta: { ...meta,
      title: "互动雷达"
    },
    component: () => import("@/pages/custom-operate/interactive-radar")
  }, {
    path: "interactive-radar/create",
    name: `${pre}interactive-radar-create`,
    meta: { ...meta,
      title: "新建雷达"
    },
    component: () => import("@/pages/custom-operate/interactive-radar/create.vue")
  }, {
    path: "interactive-radar/info",
    name: `${pre}interactive-radar-info`,
    meta: { ...meta,
      title: "雷达详情"
    },
    component: () => import("@/pages/custom-operate/interactive-radar/info.vue")
  }, {
    path: 'speechcraft',
    name: `${pre}speechcraft`,
    meta: { ...meta,
      title: '话术库'
    },
    component: () => import('@/pages/custom-operate/speechcraft')
  }, {
    path: "make-tag",
    name: `${pre}make-tag`,
    meta: { ...meta,
      title: "自动打标签"
    },
    component: () => import("@/pages/custom-operate/make-tag")
  }, {
    path: "make-tag/create",
    name: `${pre}make-tag-create`,
    meta: { ...meta,
      title: "添加规则"
    },
    component: () => import("@/pages/custom-operate/make-tag/components/create.vue")
  }, {
    path: "make-tag/info",
    name: `${pre}make-tag-info`,
    meta: { ...meta,
      title: "打标签详情"
    },
    component: () => import("@/pages/custom-operate/make-tag/components/info.vue")
  }, {
    path: 'customer-statistics',
    name: `${pre}customer-statistics`,
    meta: { ...meta,
      title: '客户群统计'
    },
    component: () => import('@/pages/custom-operate/customer-statistics')
  }]
};