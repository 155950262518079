/* unplugin-vue-components disabled */import { mapState } from 'vuex';
export default {
  name: 'iHeaderSearch',
  computed: { ...mapState('admin/layout', ['isDesktop', 'headerMenu'])
  },
  methods: {
    handleCloseSearch() {
      this.$refs.dropdown.handleClick();
    }

  }
};