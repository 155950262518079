const pre = "/custom-operate/";
export default {
  path: "/custom-operate",
  title: "客户运营",
  header: "home",
  icon: "md-contact",
  img: "https://qiniu.hp.gimyingao.com/wecom/%E5%AE%A2%E6%88%B72.png",
  imgS: "https://qiniu.hp.gimyingao.com/wecom/%E5%AE%A2%E6%88%B7.png",
  children: [{
    path: `${pre}mass-send`,
    title: "客户群发"
  }, // {
  //     path: `${pre}crowd-send`,
  //     title: '客户群群发'
  // },
  // {
  //     path: `${pre}crowd-send/info`,
  //     title: '群发详情',
  //     hide: true
  // },
  // {
  //     path: `${pre}crowd-send/add`,
  //     title: '新建群发',
  //     hide: true
  // },
  {
    path: `${pre}mass-send/info`,
    title: "群发详情",
    hide: true
  }, {
    path: `${pre}mass-send/add`,
    title: "新建群发",
    hide: true
  }, {
    path: `${pre}customer-management`,
    title: "客户管理"
  }, {
    path: `${pre}delete-reminder`,
    title: "删人提醒"
  }, {
    path: `${pre}customer-migration`,
    title: "客户迁移"
  }, {
    path: `${pre}customer-migration/record`,
    title: "分配记录",
    hide: true
  }, {
    path: `${pre}interactive-radar`,
    title: "互动雷达"
  }, {
    path: `${pre}interactive-radar/create`,
    title: "新建雷达",
    hide: true
  }, {
    path: `${pre}interactive-radar/info`,
    title: "雷达详情",
    hide: true
  }, {
    path: `${pre}make-tag`,
    title: "自动打标签"
  }, {
    path: `${pre}make-tag/add`,
    title: "添加规则",
    hide: true
  }, {
    path: `${pre}make-tag/info`,
    title: "打标签详情",
    hide: true
  }, {
    path: `${pre}customer-label`,
    title: "客户标签"
  }, {
    path: `${pre}customer-statistics`,
    title: '客户统计'
  }]
};