/* unplugin-vue-components disabled */import { mapState } from 'vuex';
export default {
  name: 'iHeaderNotice',

  data() {
    return {
      badgeProps: {
        offset: [20, 0]
      }
    };
  },

  computed: { ...mapState('admin/layout', ['isMobile'])
  }
};