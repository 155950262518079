import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "custom-manage-";
export default {
  path: "/custom-manage",
  name: "custom-manage",
  redirect: {
    name: `${pre}customer-management`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: "customer-management",
    name: `${pre}customer-management`,
    meta: { ...meta,
      title: "客户列表"
    },
    component: () => import("@/pages/custom-manage/customer-management")
  }, {
    path: "delete-reminder",
    name: `${pre}delete-reminder`,
    meta: { ...meta,
      title: "删人提醒"
    },
    component: () => import("@/pages/custom-manage/delete-reminder")
  }, {
    path: "customer-migration",
    name: `${pre}customer-migration`,
    meta: { ...meta,
      title: "客户迁移"
    },
    component: () => import("@/pages/custom-manage/customer-migration")
  }, {
    path: "customer-migration/record",
    name: `${pre}customer-migration-record`,
    meta: { ...meta,
      title: "分配记录"
    },
    component: () => import("@/pages/custom-manage/customer-migration/allocationRecord")
  }, {
    path: "customer-label",
    name: `${pre}customer-label`,
    meta: { ...meta,
      title: "客户标签"
    },
    component: () => import("@/pages/custom-manage/customer-label")
  }, {
    path: "export-list",
    name: `${pre}export-list`,
    meta: { ...meta,
      title: "导出记录"
    },
    component: () => import("@/pages/custom-manage/customer-management/components/exportList.vue")
  }]
};